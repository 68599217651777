import { breakpointMap as u } from "@machineq/theme";
import v, { memo as k, useRef as E, useState as b, useCallback as y, useEffect as C, Children as P } from "react";
const A = k(function({
  atOrLessThan: o,
  atOrGreaterThan: n,
  lessThan: f,
  greaterThan: d,
  method: e = "add-remove",
  only: s,
  children: c
}) {
  const p = E(null), [w, m] = b(!1), l = y(() => {
    p.current && e === "show-hide" && p.current.style.setProperty("display", "none"), e === "add-remove" && m((i) => i && !1);
  }, [e]), t = y(() => {
    p.current && e === "show-hide" && p.current.style.removeProperty("display"), e === "add-remove" && m((i) => i || !0);
  }, [e]);
  if (C(() => {
    function i() {
      if (typeof document < "u") {
        const { innerWidth: r } = window;
        if (s) {
          if (s === "mobile" && r <= u.tablet || s === "tablet" && r >= u.tablet && r < u.desktop || s === "desktop" && r >= u.desktop)
            return t();
          l();
        }
        if (d && f)
          throw new Error(
            "Cannot use greaterThan AND lessThan at the same time. Please pick one."
          );
        if (d && f && (n || o))
          throw new Error(
            "Can only use one responsive prop at a time. Please pick one."
          );
        if (f && !d && r < u[f] || d && !f && r > u[d])
          return t();
        if (n && o && (n || o))
          throw new Error(
            "Can only use one responsive prop at a time. Please pick one."
          );
        if (n && o)
          throw new Error(
            "Cannot use atOrGreaterThan AND atOrLessThan at the same time. Please pick one."
          );
        if (o && !n && r <= u[o] || n && !o && r >= u[n])
          return t();
        l();
      }
    }
    return typeof document < "u" && (window.addEventListener("resize", i), i()), () => {
      if (typeof document < "u")
        return window.removeEventListener("resize", i);
    };
  }, [
    n,
    o,
    d,
    l,
    f,
    e,
    s,
    t
  ]), !P.only(c) || !c)
    throw new Error(
      "You must only have 1 element as a child of this component."
    );
  return e === "add-remove" && !w ? null : e === "add-remove" && w ? c : v.cloneElement(c, {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ref: p,
    style: { display: "none" }
  });
});
export {
  A as MediaQuery
};
